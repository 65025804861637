<template>
  <div>
    <h3>Üye Bilgisi Olmayan Başvurular</h3>
    <ul>
      <li v-for="(app, i) in apps" :key="i">
        {{ app.id }}
      </li>
    </ul>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import firebase from "@/plugins/firebase";

@Component
export default class NullMemberApps extends Vue {
  apps = [];

  async mounted() {
    const appsQ = await firebase
      .firestore()
      .collection("apps/kahev-org/scholarshipApps")
      .where("member", "==", null)
      .get();

    appsQ.forEach(appDoc => {
      this.apps.push({
        id: appDoc.id,
        dateCreated: appDoc.data().dateCreated.toDate()
      });
    });
  }
}
</script>
